import React, { Component } from 'react'
import { connect } from 'react-redux'
import {

  Header,
  Form,
} from 'semantic-ui-react'

// import config from '../../config'

class Billing extends Component {
  updateValue(itemToUpdate, valueToUpdate) {
    this.props.dispatch({
      type: 'UPDATE_COUNTRY',
      item: itemToUpdate,
      value: valueToUpdate,
    })
  }

  updateState(data) {
    this.props.dispatch({
      type: 'UPDATE_STATE',
      item: data.name,
      value: data.value,
    })
  }

  render() {
    return (
      <div>
        <Header as="h1">Billing Information</Header>

        <Form>
          <Form.Group widths="equal">
            <Form.Input
              required
              name="billingName"
              autoComplete="name"
              defaultValue={this.props.billingName}
              fluid
              label="Name"
              placeholder="Full Name"
              onChange={(e, data) => {
                this.updateState(data)
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              required
              fluid
              name="billingAddress1"
              defaultValue={this.props.billingAddress1}
              autoComplete="address-line1"
              label="Street Address"
              placeholder="Street Address"
              onChange={(e, data) => {
                this.updateState(data)
              }}
            />
            <Form.Input
              fluid
              name="billingAddress2"
              defaultValue={this.props.billingAddress2}
              autoComplete="address-line2"
              label="Suite or Appartment #"
              placeholder="Suite or Appartment #"
              onChange={(e, data) => {
                this.updateState(data)
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              required
              fluid
              name="billingCity"
              defaultValue={this.props.billingCity}
              autoComplete="address-level2 city"
              label="City"
              placeholder="City"
              onChange={(e, data) => {
                this.updateState(data)
              }}
            />
            <Form.Input
              required
              fluid
              name="billingZip"
              defaultValue={this.props.billingZip}
              autoComplete="postal-code zip"
              label="ZIP / Postal Code"
              placeholder="ZIP or Postal Code"
              onChange={(e, data) => {
                this.updateState(data)
              }}
            />
            <Form.Input
              label="Country"
              name="billingCountry"
              required
              defaultValue={this.props.billingCountry}
              onChange={(e, data) => {
                this.updateValue('billingCountry', data.value)
              }}
            />
            <Form.Input
              label="State"
              name="billingState"
              required
              defaultValue={this.props.billingState}
              onChange={(e, data) => {
                this.updateValue('billingState', data.value)
              }}
            />
            
          </Form.Group>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    billingName: state.billingName,
    billingAddress1: state.billingAddress1,
    billingAddress2: state.billingAddress2,
    billingCity: state.billingCity,
    billingState: state.billingState,
    billingZip: state.billingZip,
    billingCountry: state.billingCountry,
  }
}

export default connect(mapStateToProps)(Billing)

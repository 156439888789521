import React, { Component } from 'react'
import { connect } from 'react-redux'
import countries from './countries'
import {
  Container,
  Header,
  Form,
  Segment,
  Checkbox,
  Grid,
} from 'semantic-ui-react'

import config from '../../config'
import BillingForm from './billing'

class Shipping extends Component {
  constructor() {
    super()
    this.state = {
      loadingOptions: false,
      disableShippingButton: false,
    }
  }

  // Redux Store Calls
  updateState(data) {
    this.props.dispatch({
      type: 'UPDATE_STATE',
      item: data.name,
      value: data.value,
    })
  }

  updateValue(itemToUpdate, valueToUpdate) {
    this.props.dispatch({
      type: 'UPDATE_COUNTRY',
      item: itemToUpdate,
      value: valueToUpdate,
    })
  }

  updateStepper = stage => {
    this.props.dispatch({ type: 'STEPPER_STATE', stepper: stage })
  }

  // removeShippingRates = () => {
  //   this.props.dispatch({
  //     type: 'REMOVE_SHIPPING_RATES',
  //   })
  // }

  // Read this.prop.shippingCountry, and check its state list, if it has states then display an alphabetically sorted drop down
  countryListDropdown = countries => {
    const sortedCountries = countries.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
    const countryList = sortedCountries.map(unit => {
      return {
        key: unit.code,
        id: unit.code,
        text: unit.name,
        value: unit.code,
      }
    })

    return countryList
  }

  stateListDropdown = countryCode => {
    if (countryCode === 'CA' || countryCode === 'US' || countryCode === 'AU') {
      const country = countries.find(country => country.code === countryCode)
      const sortedStates = country.states.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })

      const states = sortedStates.map(unit => {
        return {
          key: unit.code,
          id: unit.code,
          text: unit.name,
          value: unit.code,
        }
      })
      const stateLabel = () => {
        if (countryCode === 'CA') {
          return 'Province/Territory'
        } else if (countryCode === 'US') {
          return 'State'
        } else if (countryCode === 'AU') {
          return 'State/Territory'
        }
      }
      return (
        <Form.Dropdown
          label={stateLabel()}
          required
          defaultValue={this.props.shippingState}
          options={states}
          onChange={(e, data) => {
            this.updateValue('shippingState', data.value)
          }}
          selection
          search
        />
        // <Form.Select
        //   required
        //   label={stateLabel()}
        //   defaultValue={this.props.shippingState}
        //   options={states}
        //   onChange={(e, data) => {
        //     this.updateValue('shippingState', data.value)
        //   }}
        // />
      )
    } else {
      return (
        <Form.Input
          label="State/County/Province"
          defaultValue={this.props.shippingState}
          onChange={(e, data) => {
            this.updateValue('shippingState', data.value)
          }}
        />
      )
    }
  }

  shippingForm = () => {
    return (
      <Container fluid>
        <Grid style={{ marginBottom: '10px' }}>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <Header as="h1">Shipping Information</Header>
          </Grid.Column>
          <Grid.Column
            computer={8}
            tablet={16}
            mobile={16}
            textAlign="right"
            verticalAlign="middle"
          >
            <Checkbox
              toggle
              label="Use shipping address for billing"
              checked={!this.props.showBilling}
              onChange={() =>
                this.updateState({
                  name: 'showBilling',
                  value: !this.props.showBilling,
                })
              }
            />
          </Grid.Column>
        </Grid>
        <Form
          style={{ marginTop: '10px' }}
          onSubmit={e => {
            // this.updateShippingAddress({
            //   name: this.props.shippingName,
            //   email: this.props.clientEmail,
            //   address1: this.props.shippingAddress1,
            //   address2: this.props.shippingAddress2,
            //   city: this.props.shippingCity,
            //   state_code: this.props.shippingState,
            //   zip: this.props.shippingZip,
            //   country_code: this.props.shippingCountry,
            // })
            this.setState({
              loadingOptions: true,
              disableShippingButton: true,
            })
            this.updateStepper('payment')
          }}
        >
          <Form.Group widths="equal">
            <Form.Input
              required
              name="shippingName"
              autoComplete="name"
              defaultValue={this.props.shippingName}
              fluid
              label="Name"
              placeholder="Full Name"
              onChange={(e, data) => {
                this.updateState(data)
              }}
            />
            <Form.Input
              required
              fluid
              name="clientEmail"
              defaultValue={this.props.clientEmail}
              autoComplete="email"
              label="Email Address"
              placeholder="Email Address"
              onChange={(e, data) => {
                this.updateState(data)
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              required
              fluid
              name="shippingAddress1"
              defaultValue={this.props.shippingAddress1}
              autoComplete="address-line1"
              label="Street Address"
              placeholder="Street Address"
              onChange={(e, data) => {
                this.updateState(data)
              }}
            />
            <Form.Input
              fluid
              name="shippingAddress2"
              defaultValue={this.props.shippingAddress2}
              autoComplete="address-line2"
              label="Suite or Appartment #"
              placeholder="Suite or Appartment #"
              onChange={(e, data) => {
                this.updateState(data)
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              required
              fluid
              name="shippingCity"
              defaultValue={this.props.shippingCity}
              autoComplete="address-level2 city"
              label="City"
              placeholder="City"
              onChange={(e, data) => {
                this.updateState(data)
              }}
            />
            <Form.Input
              required
              fluid
              name="shippingZip"
              defaultValue={this.props.shippingZip}
              autoComplete="postal-code zip"
              label="ZIP / Postal Code"
              placeholder="ZIP or Postal Code"
              onChange={(e, data) => {
                this.updateState(data)
              }}
            />
            {/* <Form.Select
              label="Country"
              name="shippingCountry"
              required
              defaultValue={this.props.shippingCountry}
              options={this.countryListDropdown(countries)}
              onChange={(e, data) => {
                this.updateValue('shippingCountry', data.value)
              }}
            /> */}
            <Form.Dropdown
              label="Country"
              name="shippingCountry"
              required
              defaultValue={this.props.shippingCountry}
              options={this.countryListDropdown(countries)}
              onChange={(e, data) => {
                this.updateValue('shippingCountry', data.value)
                this.updateValue('shippingState', null)

              }}
              selection
              search
            />

            {this.stateListDropdown(this.props.shippingCountry)}
          </Form.Group>
          <Container fluid style={{ margin: '2vw' }} textAlign="right">
            <Form.Button
              loading={this.state.loadingOptions}
              disabled={this.state.disableShippingButton}
              style={{
                marginRight: '0px',
                backgroundColor: config.colors.primary,
                color: 'white',
              }}
            >
              Enter My Payment Details...
            </Form.Button>
          </Container>
        </Form>
      </Container>
    )
  }

  showBillingForm = () => {
    if (this.props.showBilling === true) {
      return <BillingForm />
    } else {
      return
    }
  }
  render() {
    return (
      <Segment>
        {this.shippingForm()}
        {this.showBillingForm()}
      </Segment>
    )
  }
}

const mapStateToProps = state => {
  return {
    shippingName: state.shippingName,
    clientEmail: state.clientEmail,
    shippingAddress1: state.shippingAddress1,
    shippingAddress2: state.shippingAddress2,
    shippingCity: state.shippingCity,
    shippingState: state.shippingState,
    shippingZip: state.shippingZip,
    shippingCountry: state.shippingCountry,
    shippingRates: state.shippingRates,
    shippingOption: state.shippingOption,
    showBilling: state.showBilling,
    cart: state.cart,
  }
}

export default connect(mapStateToProps)(Shipping)

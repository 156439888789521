// Can replace the static countries array with a fetch to printful's countries API.

const countries = [
    {
        "code": "AD",
        "name": "Andorra",
        "states": null
    },
    {
        "code": "AE",
        "name": "United Arab Emirates",
        "states": null
    },
    {
        "code": "AF",
        "name": "Afghanistan",
        "states": null
    },
    {
        "code": "AG",
        "name": "Antigua and Barbuda",
        "states": null
    },
    {
        "code": "AI",
        "name": "Anguilla",
        "states": null
    },
    {
        "code": "AL",
        "name": "Albania",
        "states": null
    },
    {
        "code": "AM",
        "name": "Armenia",
        "states": null
    },
    {
        "code": "AN",
        "name": "Netherlands Antilles",
        "states": null
    },
    {
        "code": "AO",
        "name": "Angola",
        "states": null
    },
    {
        "code": "AQ",
        "name": "Antarctica",
        "states": null
    },
    {
        "code": "AR",
        "name": "Argentina",
        "states": null
    },
    {
        "code": "AS",
        "name": "American Samoa",
        "states": null
    },
    {
        "code": "AT",
        "name": "Austria",
        "states": null
    },
    {
        "code": "AU",
        "name": "Australia",
        "states": [
            {
                "code": "ACT",
                "name": "Australian Capital Territory"
            },
            {
                "code": "NSW",
                "name": "New South Wales"
            },
            {
                "code": "NT",
                "name": "Northern Territory"
            },
            {
                "code": "QLD",
                "name": "Queensland"
            },
            {
                "code": "SA",
                "name": "South Australia"
            },
            {
                "code": "TAS",
                "name": "Tasmania"
            },
            {
                "code": "VIC",
                "name": "Victoria"
            },
            {
                "code": "WA",
                "name": "Western Australia"
            }
        ]
    },
    {
        "code": "AW",
        "name": "Aruba",
        "states": null
    },
    {
        "code": "AZ",
        "name": "Azerbaijan",
        "states": null
    },
    {
        "code": "BA",
        "name": "Bosnia and Herzegovina",
        "states": null
    },
    {
        "code": "BB",
        "name": "Barbados",
        "states": null
    },
    {
        "code": "BD",
        "name": "Bangladesh",
        "states": null
    },
    {
        "code": "BE",
        "name": "Belgium",
        "states": null
    },
    {
        "code": "BF",
        "name": "Burkina Faso",
        "states": null
    },
    {
        "code": "BG",
        "name": "Bulgaria",
        "states": null
    },
    {
        "code": "BH",
        "name": "Bahrain",
        "states": null
    },
    {
        "code": "BI",
        "name": "Burundi",
        "states": null
    },
    {
        "code": "BJ",
        "name": "Benin",
        "states": null
    },
    {
        "code": "BM",
        "name": "Bermuda",
        "states": null
    },
    {
        "code": "BN",
        "name": "Brunei Darussalam",
        "states": null
    },
    {
        "code": "BO",
        "name": "Bolivia",
        "states": null
    },
    {
        "code": "BR",
        "name": "Brazil",
        "states": null
    },
    {
        "code": "BS",
        "name": "Bahamas",
        "states": null
    },
    {
        "code": "BT",
        "name": "Bhutan",
        "states": null
    },
    {
        "code": "BV",
        "name": "Bouvet Island",
        "states": null
    },
    {
        "code": "BW",
        "name": "Botswana",
        "states": null
    },
    {
        "code": "BY",
        "name": "Belarus",
        "states": null
    },
    {
        "code": "BZ",
        "name": "Belize",
        "states": null
    },
    {
        "code": "CA",
        "name": "Canada",
        "states": [
            {
                "code": "AB",
                "name": "Alberta"
            },
            {
                "code": "BC",
                "name": "British Columbia"
            },
            {
                "code": "MB",
                "name": "Manitoba"
            },
            {
                "code": "NB",
                "name": "New Brunswick"
            },
            {
                "code": "NL",
                "name": "Newfoundland and Labrador"
            },
            {
                "code": "NS",
                "name": "Nova Scotia"
            },
            {
                "code": "NT",
                "name": "Northwest Territories"
            },
            {
                "code": "NU",
                "name": "Nunavut"
            },
            {
                "code": "ON",
                "name": "Ontario"
            },
            {
                "code": "PE",
                "name": "Prince Edward Island"
            },
            {
                "code": "QC",
                "name": "Quebec"
            },
            {
                "code": "SK",
                "name": "Saskatchewan"
            },
            {
                "code": "YT",
                "name": "Yukon"
            }
        ]
    },
    {
        "code": "CC",
        "name": "Cocos (Keeling) Islands",
        "states": null
    },
    {
        "code": "CD",
        "name": "Congo, the Democratic Republic of the",
        "states": null
    },
    {
        "code": "CF",
        "name": "Central African Republic",
        "states": null
    },
    {
        "code": "CG",
        "name": "Congo",
        "states": null
    },
    {
        "code": "CH",
        "name": "Switzerland",
        "states": null
    },
    {
        "code": "CI",
        "name": "Cote D'Ivoire",
        "states": null
    },
    {
        "code": "CK",
        "name": "Cook Islands",
        "states": null
    },
    {
        "code": "CL",
        "name": "Chile",
        "states": null
    },
    {
        "code": "CM",
        "name": "Cameroon",
        "states": null
    },
    {
        "code": "CN",
        "name": "China",
        "states": null
    },
    {
        "code": "CO",
        "name": "Colombia",
        "states": null
    },
    {
        "code": "CR",
        "name": "Costa Rica",
        "states": null
    },
    {
        "code": "CV",
        "name": "Cape Verde",
        "states": null
    },
    {
        "code": "CW",
        "name": "Curacao",
        "states": null
    },
    {
        "code": "CX",
        "name": "Christmas Island",
        "states": null
    },
    {
        "code": "CY",
        "name": "Cyprus",
        "states": null
    },
    {
        "code": "CZ",
        "name": "Czech Republic",
        "states": null
    },
    {
        "code": "DE",
        "name": "Germany",
        "states": null
    },
    {
        "code": "DJ",
        "name": "Djibouti",
        "states": null
    },
    {
        "code": "DK",
        "name": "Denmark",
        "states": null
    },
    {
        "code": "DM",
        "name": "Dominica",
        "states": null
    },
    {
        "code": "DO",
        "name": "Dominican Republic",
        "states": null
    },
    {
        "code": "DZ",
        "name": "Algeria",
        "states": null
    },
    {
        "code": "EC",
        "name": "Ecuador",
        "states": null
    },
    {
        "code": "EE",
        "name": "Estonia",
        "states": null
    },
    {
        "code": "EG",
        "name": "Egypt",
        "states": null
    },
    {
        "code": "EH",
        "name": "Western Sahara",
        "states": null
    },
    {
        "code": "ER",
        "name": "Eritrea",
        "states": null
    },
    {
        "code": "ES",
        "name": "Spain",
        "states": null
    },
    {
        "code": "ET",
        "name": "Ethiopia",
        "states": null
    },
    {
        "code": "FI",
        "name": "Finland",
        "states": null
    },
    {
        "code": "FJ",
        "name": "Fiji",
        "states": null
    },
    {
        "code": "FK",
        "name": "Falkland Islands (Malvinas)",
        "states": null
    },
    {
        "code": "FM",
        "name": "Micronesia, Federated States of",
        "states": null
    },
    {
        "code": "FO",
        "name": "Faroe Islands",
        "states": null
    },
    {
        "code": "FR",
        "name": "France",
        "states": null
    },
    {
        "code": "GA",
        "name": "Gabon",
        "states": null
    },
    {
        "code": "GB",
        "name": "United Kingdom",
        "states": null
    },
    {
        "code": "GD",
        "name": "Grenada",
        "states": null
    },
    {
        "code": "GE",
        "name": "Georgia",
        "states": null
    },
    {
        "code": "GF",
        "name": "French Guiana",
        "states": null
    },
    {
        "code": "GG",
        "name": "Guernsey",
        "states": null
    },
    {
        "code": "GH",
        "name": "Ghana",
        "states": null
    },
    {
        "code": "GI",
        "name": "Gibraltar",
        "states": null
    },
    {
        "code": "GL",
        "name": "Greenland",
        "states": null
    },
    {
        "code": "GM",
        "name": "Gambia",
        "states": null
    },
    {
        "code": "GN",
        "name": "Guinea",
        "states": null
    },
    {
        "code": "GP",
        "name": "Guadeloupe",
        "states": null
    },
    {
        "code": "GQ",
        "name": "Equatorial Guinea",
        "states": null
    },
    {
        "code": "GR",
        "name": "Greece",
        "states": null
    },
    {
        "code": "GS",
        "name": "South Georgia and the South Sandwich Islands",
        "states": null
    },
    {
        "code": "GT",
        "name": "Guatemala",
        "states": null
    },
    {
        "code": "GU",
        "name": "Guam",
        "states": null
    },
    {
        "code": "GW",
        "name": "Guinea-Bissau",
        "states": null
    },
    {
        "code": "GY",
        "name": "Guyana",
        "states": null
    },
    {
        "code": "HK",
        "name": "Hong Kong",
        "states": null
    },
    {
        "code": "HM",
        "name": "Heard Island and Mcdonald Islands",
        "states": null
    },
    {
        "code": "HN",
        "name": "Honduras",
        "states": null
    },
    {
        "code": "HR",
        "name": "Croatia",
        "states": null
    },
    {
        "code": "HT",
        "name": "Haiti",
        "states": null
    },
    {
        "code": "HU",
        "name": "Hungary",
        "states": null
    },
    {
        "code": "ID",
        "name": "Indonesia",
        "states": null
    },
    {
        "code": "IE",
        "name": "Ireland",
        "states": null
    },
    {
        "code": "IL",
        "name": "Israel",
        "states": null
    },
    {
        "code": "IM",
        "name": "Isle of Man",
        "states": null
    },
    {
        "code": "IN",
        "name": "India",
        "states": null
    },
    {
        "code": "IO",
        "name": "British Indian Ocean Territory",
        "states": null
    },
    {
        "code": "IQ",
        "name": "Iraq",
        "states": null
    },
    {
        "code": "IS",
        "name": "Iceland",
        "states": null
    },
    {
        "code": "IT",
        "name": "Italy",
        "states": null
    },
    {
        "code": "JE",
        "name": "Jersey",
        "states": null
    },
    {
        "code": "JM",
        "name": "Jamaica",
        "states": null
    },
    {
        "code": "JO",
        "name": "Jordan",
        "states": null
    },
    {
        "code": "JP",
        "name": "Japan",
        "states": null
    },
    {
        "code": "KE",
        "name": "Kenya",
        "states": null
    },
    {
        "code": "KG",
        "name": "Kyrgyzstan",
        "states": null
    },
    {
        "code": "KH",
        "name": "Cambodia",
        "states": null
    },
    {
        "code": "KI",
        "name": "Kiribati",
        "states": null
    },
    {
        "code": "KM",
        "name": "Comoros",
        "states": null
    },
    {
        "code": "KN",
        "name": "Saint Kitts and Nevis",
        "states": null
    },
    {
        "code": "KP",
        "name": "Korea, Democratic People's Republic of",
        "states": null
    },
    {
        "code": "KR",
        "name": "Korea, Republic of",
        "states": null
    },
    {
        "code": "KW",
        "name": "Kuwait",
        "states": null
    },
    {
        "code": "KY",
        "name": "Cayman Islands",
        "states": null
    },
    {
        "code": "KZ",
        "name": "Kazakhstan",
        "states": null
    },
    {
        "code": "LA",
        "name": "Lao People's Democratic Republic",
        "states": null
    },
    {
        "code": "LB",
        "name": "Lebanon",
        "states": null
    },
    {
        "code": "LC",
        "name": "Saint Lucia",
        "states": null
    },
    {
        "code": "LI",
        "name": "Liechtenstein",
        "states": null
    },
    {
        "code": "LK",
        "name": "Sri Lanka",
        "states": null
    },
    {
        "code": "LR",
        "name": "Liberia",
        "states": null
    },
    {
        "code": "LS",
        "name": "Lesotho",
        "states": null
    },
    {
        "code": "LT",
        "name": "Lithuania",
        "states": null
    },
    {
        "code": "LU",
        "name": "Luxembourg",
        "states": null
    },
    {
        "code": "LV",
        "name": "Latvia",
        "states": null
    },
    {
        "code": "LY",
        "name": "Libyan Arab Jamahiriya",
        "states": null
    },
    {
        "code": "MA",
        "name": "Morocco",
        "states": null
    },
    {
        "code": "MC",
        "name": "Monaco",
        "states": null
    },
    {
        "code": "MD",
        "name": "Moldova, Republic of",
        "states": null
    },
    {
        "code": "ME",
        "name": "Montenegro",
        "states": null
    },
    {
        "code": "MF",
        "name": "Sint Maarten",
        "states": null
    },
    {
        "code": "MG",
        "name": "Madagascar",
        "states": null
    },
    {
        "code": "MH",
        "name": "Marshall Islands",
        "states": null
    },
    {
        "code": "MK",
        "name": "Macedonia, the Former Yugoslav Republic of",
        "states": null
    },
    {
        "code": "ML",
        "name": "Mali",
        "states": null
    },
    {
        "code": "MM",
        "name": "Myanmar",
        "states": null
    },
    {
        "code": "MN",
        "name": "Mongolia",
        "states": null
    },
    {
        "code": "MO",
        "name": "Macao",
        "states": null
    },
    {
        "code": "MP",
        "name": "Northern Mariana Islands",
        "states": null
    },
    {
        "code": "MQ",
        "name": "Martinique",
        "states": null
    },
    {
        "code": "MR",
        "name": "Mauritania",
        "states": null
    },
    {
        "code": "MS",
        "name": "Montserrat",
        "states": null
    },
    {
        "code": "MT",
        "name": "Malta",
        "states": null
    },
    {
        "code": "MU",
        "name": "Mauritius",
        "states": null
    },
    {
        "code": "MV",
        "name": "Maldives",
        "states": null
    },
    {
        "code": "MW",
        "name": "Malawi",
        "states": null
    },
    {
        "code": "MX",
        "name": "Mexico",
        "states": null
    },
    {
        "code": "MY",
        "name": "Malaysia",
        "states": null
    },
    {
        "code": "MZ",
        "name": "Mozambique",
        "states": null
    },
    {
        "code": "NA",
        "name": "Namibia",
        "states": null
    },
    {
        "code": "NC",
        "name": "New Caledonia",
        "states": null
    },
    {
        "code": "NE",
        "name": "Niger",
        "states": null
    },
    {
        "code": "NF",
        "name": "Norfolk Island",
        "states": null
    },
    {
        "code": "NG",
        "name": "Nigeria",
        "states": null
    },
    {
        "code": "NI",
        "name": "Nicaragua",
        "states": null
    },
    {
        "code": "NL",
        "name": "Netherlands",
        "states": null
    },
    {
        "code": "NO",
        "name": "Norway",
        "states": null
    },
    {
        "code": "NP",
        "name": "Nepal",
        "states": null
    },
    {
        "code": "NR",
        "name": "Nauru",
        "states": null
    },
    {
        "code": "NU",
        "name": "Niue",
        "states": null
    },
    {
        "code": "NZ",
        "name": "New Zealand",
        "states": null
    },
    {
        "code": "OM",
        "name": "Oman",
        "states": null
    },
    {
        "code": "PA",
        "name": "Panama",
        "states": null
    },
    {
        "code": "PE",
        "name": "Peru",
        "states": null
    },
    {
        "code": "PF",
        "name": "French Polynesia",
        "states": null
    },
    {
        "code": "PG",
        "name": "Papua New Guinea",
        "states": null
    },
    {
        "code": "PH",
        "name": "Philippines",
        "states": null
    },
    {
        "code": "PK",
        "name": "Pakistan",
        "states": null
    },
    {
        "code": "PL",
        "name": "Poland",
        "states": null
    },
    {
        "code": "PM",
        "name": "Saint Pierre and Miquelon",
        "states": null
    },
    {
        "code": "PN",
        "name": "Pitcairn",
        "states": null
    },
    {
        "code": "PR",
        "name": "Puerto Rico",
        "states": null
    },
    {
        "code": "PS",
        "name": "Palestinian Territory, Occupied",
        "states": null
    },
    {
        "code": "PT",
        "name": "Portugal",
        "states": null
    },
    {
        "code": "PW",
        "name": "Palau",
        "states": null
    },
    {
        "code": "PY",
        "name": "Paraguay",
        "states": null
    },
    {
        "code": "QA",
        "name": "Qatar",
        "states": null
    },
    {
        "code": "RE",
        "name": "Reunion",
        "states": null
    },
    {
        "code": "RO",
        "name": "Romania",
        "states": null
    },
    {
        "code": "RS",
        "name": "Serbia",
        "states": null
    },
    {
        "code": "RU",
        "name": "Russian Federation",
        "states": null
    },
    {
        "code": "RW",
        "name": "Rwanda",
        "states": null
    },
    {
        "code": "SA",
        "name": "Saudi Arabia",
        "states": null
    },
    {
        "code": "SB",
        "name": "Solomon Islands",
        "states": null
    },
    {
        "code": "SC",
        "name": "Seychelles",
        "states": null
    },
    {
        "code": "SD",
        "name": "Sudan",
        "states": null
    },
    {
        "code": "SE",
        "name": "Sweden",
        "states": null
    },
    {
        "code": "SG",
        "name": "Singapore",
        "states": null
    },
    {
        "code": "SH",
        "name": "Saint Helena",
        "states": null
    },
    {
        "code": "SI",
        "name": "Slovenia",
        "states": null
    },
    {
        "code": "SJ",
        "name": "Svalbard and Jan Mayen",
        "states": null
    },
    {
        "code": "SK",
        "name": "Slovakia",
        "states": null
    },
    {
        "code": "SL",
        "name": "Sierra Leone",
        "states": null
    },
    {
        "code": "SM",
        "name": "San Marino",
        "states": null
    },
    {
        "code": "SN",
        "name": "Senegal",
        "states": null
    },
    {
        "code": "SO",
        "name": "Somalia",
        "states": null
    },
    {
        "code": "SR",
        "name": "Suriname",
        "states": null
    },
    {
        "code": "ST",
        "name": "Sao Tome and Principe",
        "states": null
    },
    {
        "code": "SV",
        "name": "El Salvador",
        "states": null
    },
    {
        "code": "SY",
        "name": "Syrian Arab Republic",
        "states": null
    },
    {
        "code": "SZ",
        "name": "Swaziland",
        "states": null
    },
    {
        "code": "TC",
        "name": "Turks and Caicos Islands",
        "states": null
    },
    {
        "code": "TD",
        "name": "Chad",
        "states": null
    },
    {
        "code": "TF",
        "name": "French Southern Territories",
        "states": null
    },
    {
        "code": "TG",
        "name": "Togo",
        "states": null
    },
    {
        "code": "TH",
        "name": "Thailand",
        "states": null
    },
    {
        "code": "TJ",
        "name": "Tajikistan",
        "states": null
    },
    {
        "code": "TK",
        "name": "Tokelau",
        "states": null
    },
    {
        "code": "TL",
        "name": "Timor-Leste",
        "states": null
    },
    {
        "code": "TM",
        "name": "Turkmenistan",
        "states": null
    },
    {
        "code": "TN",
        "name": "Tunisia",
        "states": null
    },
    {
        "code": "TO",
        "name": "Tonga",
        "states": null
    },
    {
        "code": "TR",
        "name": "Turkey",
        "states": null
    },
    {
        "code": "TT",
        "name": "Trinidad and Tobago",
        "states": null
    },
    {
        "code": "TV",
        "name": "Tuvalu",
        "states": null
    },
    {
        "code": "TW",
        "name": "Taiwan",
        "states": null
    },
    {
        "code": "TZ",
        "name": "Tanzania",
        "states": null
    },
    {
        "code": "UA",
        "name": "Ukraine",
        "states": null
    },
    {
        "code": "UG",
        "name": "Uganda",
        "states": null
    },
    {
        "code": "UM",
        "name": "United States Minor Outlying Islands",
        "states": null
    },
    {
        "code": "US",
        "name": "United States",
        "states": [
            {
                "code": "AA",
                "name": "Armed Forces Americas (except Canada)"
            },
            {
                "code": "AE",
                "name": "Armed Forces"
            },
            {
                "code": "AK",
                "name": "Alaska"
            },
            {
                "code": "AL",
                "name": "Alabama"
            },
            {
                "code": "AP",
                "name": "Armed Forces Pacific"
            },
            {
                "code": "AR",
                "name": "Arkansas"
            },
            {
                "code": "AS",
                "name": "American Samoa"
            },
            {
                "code": "AZ",
                "name": "Arizona"
            },
            {
                "code": "CA",
                "name": "California"
            },
            {
                "code": "CO",
                "name": "Colorado"
            },
            {
                "code": "CT",
                "name": "Connecticut"
            },
            {
                "code": "DC",
                "name": "District of Columbia"
            },
            {
                "code": "DE",
                "name": "Delaware"
            },
            {
                "code": "FL",
                "name": "Florida"
            },
            {
                "code": "FM",
                "name": "Federated States of Micronesia"
            },
            {
                "code": "GA",
                "name": "Georgia"
            },
            {
                "code": "GU",
                "name": "Guam"
            },
            {
                "code": "HI",
                "name": "Hawaii"
            },
            {
                "code": "IA",
                "name": "Iowa"
            },
            {
                "code": "ID",
                "name": "Idaho"
            },
            {
                "code": "IL",
                "name": "Illinois"
            },
            {
                "code": "IN",
                "name": "Indiana"
            },
            {
                "code": "KS",
                "name": "Kansas"
            },
            {
                "code": "KY",
                "name": "Kentucky"
            },
            {
                "code": "LA",
                "name": "Louisiana"
            },
            {
                "code": "MA",
                "name": "Massachusetts"
            },
            {
                "code": "MD",
                "name": "Maryland"
            },
            {
                "code": "ME",
                "name": "Maine"
            },
            {
                "code": "MH",
                "name": "Marshall Islands"
            },
            {
                "code": "MI",
                "name": "Michigan"
            },
            {
                "code": "MN",
                "name": "Minnesota"
            },
            {
                "code": "MO",
                "name": "Missouri"
            },
            {
                "code": "MP",
                "name": "Northern Mariana Islands"
            },
            {
                "code": "MS",
                "name": "Mississippi"
            },
            {
                "code": "MT",
                "name": "Montana"
            },
            {
                "code": "NC",
                "name": "North Carolina"
            },
            {
                "code": "ND",
                "name": "North Dakota"
            },
            {
                "code": "NE",
                "name": "Nebraska"
            },
            {
                "code": "NH",
                "name": "New Hampshire"
            },
            {
                "code": "NJ",
                "name": "New Jersey"
            },
            {
                "code": "NM",
                "name": "New Mexico"
            },
            {
                "code": "NV",
                "name": "Nevada"
            },
            {
                "code": "NY",
                "name": "New York"
            },
            {
                "code": "OH",
                "name": "Ohio"
            },
            {
                "code": "OK",
                "name": "Oklahoma"
            },
            {
                "code": "OR",
                "name": "Oregon"
            },
            {
                "code": "PA",
                "name": "Pennsylvania"
            },
            {
                "code": "PR",
                "name": "Puerto Rico"
            },
            {
                "code": "PW",
                "name": "Palau"
            },
            {
                "code": "RI",
                "name": "Rhode Island"
            },
            {
                "code": "SC",
                "name": "South Carolina"
            },
            {
                "code": "SD",
                "name": "South Dakota"
            },
            {
                "code": "TN",
                "name": "Tennessee"
            },
            {
                "code": "TX",
                "name": "Texas"
            },
            {
                "code": "UT",
                "name": "Utah"
            },
            {
                "code": "VA",
                "name": "Virginia"
            },
            {
                "code": "VI",
                "name": "Virgin Islands"
            },
            {
                "code": "VT",
                "name": "Vermont"
            },
            {
                "code": "WA",
                "name": "Washington"
            },
            {
                "code": "WI",
                "name": "Wisconsin"
            },
            {
                "code": "WV",
                "name": "West Virginia"
            },
            {
                "code": "WY",
                "name": "Wyoming"
            }
        ]
    },
    {
        "code": "UY",
        "name": "Uruguay",
        "states": null
    },
    {
        "code": "UZ",
        "name": "Uzbekistan",
        "states": null
    },
    {
        "code": "VA",
        "name": "Vatican City",
        "states": null
    },
    {
        "code": "VC",
        "name": "Saint Vincent and the Grenadines",
        "states": null
    },
    {
        "code": "VE",
        "name": "Venezuela",
        "states": null
    },
    {
        "code": "VG",
        "name": "Virgin Islands, British",
        "states": null
    },
    {
        "code": "VI",
        "name": "Virgin Islands, U.S.",
        "states": null
    },
    {
        "code": "VN",
        "name": "Vietnam",
        "states": null
    },
    {
        "code": "VU",
        "name": "Vanuatu",
        "states": null
    },
    {
        "code": "WF",
        "name": "Wallis and Futuna",
        "states": null
    },
    {
        "code": "WS",
        "name": "Samoa",
        "states": null
    },
    {
        "code": "YE",
        "name": "Yemen",
        "states": null
    },
    {
        "code": "YT",
        "name": "Mayotte",
        "states": null
    },
    {
        "code": "ZA",
        "name": "South Africa",
        "states": null
    },
    {
        "code": "ZM",
        "name": "Zambia",
        "states": null
    },
    {
        "code": "ZW",
        "name": "Zimbabwe",
        "states": null
    }
]

export default countries
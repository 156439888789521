import React from 'react'
import { navigate } from 'gatsby'
import uuid from 'uuid/v4'
import { connect } from 'react-redux'
import { injectStripe } from 'react-stripe-elements'

import {
  Button,
  Form,
  Segment,
  Header,
  Table,
  Label,
  Modal,
  Icon,
  Radio,
  Divider,
} from 'semantic-ui-react'

import CardSection from './CardSection'

import config from '../../../config'

class CheckoutForm extends React.Component {
  constructor() {
    super()
    this.state = {
      showModal: false,
      response: {},
      confirmOrderButtonDisabled: true,
      confirmOrderButtonLoader: false,
      shippingMessage: true,
    }
  }
  componentDidMount = () => {
    if (this.props.shippingRates.result) {
      this.setState({
        confirmOrderButtonDisabled: false,
        shippingMessage: false,
      })
    }

    if (
      this.props.shippingName &&
      this.props.clientEmail &&
      this.props.shippingAddress1 &&
      this.props.shippingCity &&
      this.props.shippingState &&
      this.props.shippingZip &&
      this.props.shippingCountry
    ) {
      this.updateShippingAddress({
        name: this.props.shippingName,
        email: this.props.clientEmail,
        address1: this.props.shippingAddress1,
        address2: this.props.shippingAddress2,
        city: this.props.shippingCity,
        state_code: this.props.shippingState,
        zip: this.props.shippingZip,
        country_code: this.props.shippingCountry,
      })
    }

    if (this.props.showBilling === false) {
      this.props.dispatch({
        type: 'UPDATE_STATE',
        item: 'billingName',
        value: this.props.shippingName,
      })
      this.props.dispatch({
        type: 'UPDATE_STATE',
        item: 'billingAddress1',
        value: this.props.shippingAddress1,
      })
      this.props.dispatch({
        type: 'UPDATE_STATE',
        item: 'billingAddress2',
        value: this.props.shippingAddress2,
      })
      this.props.dispatch({
        type: 'UPDATE_STATE',
        item: 'billingCity',
        value: this.props.shippingCity,
      })
      this.props.dispatch({
        type: 'UPDATE_STATE',
        item: 'billingState',
        value: this.props.shippingState,
      })
      this.props.dispatch({
        type: 'UPDATE_STATE',
        item: 'billingCountry',
        value: this.props.shippingCountry,
      })
      this.props.dispatch({
        type: 'UPDATE_STATE',
        item: 'billingZip',
        value: this.props.shippingZip,
      })
    }
  }

  shippingRate(rates) {
    this.props.dispatch({
      type: 'UPDATE_SHIPPING_RATE',
      rates: rates,
    })
    // call an update to shippingAmount - ensure that a previous selection of shipping option does error if no longer available

    let options = this.props.shippingRates.result
    let selection = 0
    if (this.props.shippingOption > options.length) {
      selection = 0
    } else {
      selection = this.props.shippingOption
    }
    let shippingAmount = options[selection].rate
    let shippingID = options[selection].id

    this.props.dispatch({
      type: 'UPDATE_STATE',
      item: 'shippingAmount',
      value: shippingAmount,
    })
    this.props.dispatch({
      type: 'UPDATE_STATE',
      item: 'shippingID',
      value: shippingID,
    })
  }
  // Double check the shipping rate incase someone skips the shipping page
  updateShipping = () => {
    if (this.props.shippingRates.result) {
      let options = this.props.shippingRates.result

      let selection = 0
      if (this.props.shippingOption >= options.length) {
        selection = 0
      } else {
        selection = this.props.shippingOption
      }
      let shippingAmount = options[selection].rate
      this.props.dispatch({
        type: 'UPDATE_STATE',
        item: 'shippingAmount',
        value: shippingAmount,
      })
      return shippingAmount
    } else {
      return 'T.B.D'
    }
  }

  // UI for shipping Rates once fetched.
  updateShippingAddress(shipping) {
    fetch('.netlify/functions/shipping', {
      method: 'POST',
      body: JSON.stringify({
        recipient: shipping,
        items: this.props.cart,
      }),
    })
      .then(response => response.json())
      .then(json => {
        // console.log(json)
        this.shippingRate(json)

        // update shipping amount
        this.setState({
          confirmOrderButtonDisabled: false,
          confirmOrderButtonLoader: false,
          shippingMessage: false,
        })
      })
      .catch(error => {
        console.log('Fetch failed:' + error)
      })
  }

  updateShippingOption(rate, key) {
    this.props.dispatch({
      type: 'UPDATE_STATE',
      item: 'shippingOption',
      value: key,
    })
    this.props.dispatch({
      type: 'UPDATE_STATE',
      item: 'shippingAmount',
      value: rate.rate,
    })
  }

  checkShippingState = () => {
    if (this.props.shippingRates.result) {
      this.setState({
        confirmOrderButtonDisabled: false,
        shippingMessage: false,
      })
    }
  }

  saveToken = token => {
    this.props.dispatch({
      type: 'UPDATE_STATE',
      item: 'stripeToken',
      value: token,
    })
  }

  clearCart = () => {
    this.props.dispatch({
      type: 'EMPTY_CART',
    })
  }

  handleSubmit = ev => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault()

    this.setState({
      confirmOrderButtonDisabled: true,
      confirmOrderButtonLoader: true,
    })
    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
    this.props.stripe
      .createToken({
        name: this.props.billingName,
        address_line1: this.props.billingAddress1,
        address_line2: this.props.billingAddress2,
        address_city: this.props.billingCity,
        address_state: this.props.billingState,
        address_zip: this.props.billingZip,
        address_country: this.props.billingCountry,
        email: this.props.clientEmail,
      })
      .then(({ token }) => {
      
        this.saveToken(token)
        // console.log('token, ',token)
        // console.log('cart, ', this.props.cart)
        // console.log('shippingOption: ', this.props.shippingOption)
        // console.log('shippingAddress: ', {
        //   address_line1: this.props.shippingAddress1,
        //   address_line2: this.props.shippingAddress2,
        //   address_city: this.props.shippingCity,
        //   address_state: this.props.shippingState,
        //   address_zip: this.props.shippingZip,
        //   address_country: this.props.shippingCountry,
        // })
        // console.log('billingAddress: ', {
        //   address_line1: this.props.billingAddress1,
        //   address_line2: this.props.billingAddress2,
        //   address_city: this.props.billingCity,
        //   address_state: this.props.billingState,
        //   address_zip: this.props.billingZip,
        //   address_country: this.props.billingCountry,
        // })
        fetch('.netlify/functions/purchasehat', {
          method: 'POST',
          body: JSON.stringify({
            token,
            cart: this.props.cart,
            idempotency_key: uuid(),
            shippingOption: this.props.shippingID,
            shippingAddress: {
              address_line1: this.props.shippingAddress1,
              address_line2: this.props.shippingAddress2,
              address_city: this.props.shippingCity,
              address_state: this.props.shippingState,
              address_zip: this.props.shippingZip,
              address_country: this.props.shippingCountry,
            },
            
            billingAddress: {
              address_line1: this.props.billingAddress1,
              address_line2: this.props.billingAddress2,
              address_city: this.props.billingCity,
              address_state: this.props.billingState,
              address_zip: this.props.billingZip,
              address_country: this.props.billingCountry,
            },
          }),
        })
          .then(res => res.json())
          .then(res => {
            if (res.code === 'success') {
              this.clearCart()
              navigate('/confirmation')
            } else {
              this.setState({
                confirmOrderButtonDisabled: false,
                confirmOrderButtonLoader: false,
                showModal: true,
                response: res.message,
              })
            }
          })
      })
      .catch(err => {
        console.log('Fetch failed :', err)
      })
  }

  // Shipping results UI, to be shown once we have somethign back from Printful
  shippingResults = () => {
    const rates = this.props.shippingRates

    if (rates.code === 200) {
      return (
        <div>
          <label>{config.shippingDescription}</label>
          <Form.Group>
            {rates.result.map((rate, key) => {
              return (
                <Form.Field
                  name="shippingOption"
                  key={key}
                  control={Radio}
                  label={rate.name + ' $ ' + rate.rate}
                  value={key}
                  checked={this.props.shippingOption === key}
                  onClick={(e, data) => {
                    this.updateShippingOption(rate, key)
                  }}
                />
              )
            })}
          </Form.Group>
        </div>
      )
    } else if (rates.code === 400) {
      return (
        <Segment>
          <Header as="h2">{rates.error.message}</Header>
        </Segment>
      )
    } else {
      return
    }
  }

  shippingMessage = () => {
    if (this.state.shippingMessage === true) {
      return 'Please enter your shipping information and select a shipping method before confirming your order '
    } else {
      return
    }
  }
  showAddress = () => {
    if (
      this.props.shippingAddress1 &&
      this.props.shippingCity &&
      this.props.shippingState &&
      this.props.shippingZip &&
      this.props.shippingCountry
    ) {
      return (
        <div>
          {this.props.shippingAddress1} {', '}
          {this.props.shippingAddress2}
          <br />
          {this.props.shippingCity}
          {', '}
          {this.props.shippingState}
          <br />
          {this.props.shippingZip}
          {', '}
          {this.props.shippingCountry}
          <br />
        </div>
      )
    } else {
      return 'please fill in shipping details'
    }
  }
  render() {
    return (
      <Segment>
        <Header as="h1">Credit Card Details</Header>

        <Segment>
          <h3>Shipping to:</h3>
          {this.showAddress()}
          <Divider />
          {this.shippingResults()}
        </Segment>
        <Table stackable={false}>
          <Table.Body>
            <Table.Row>
              <Table.Cell textAlign="left">Cart Total</Table.Cell>
              <Table.Cell textAlign="right">
                <Label tag>${this.props.orderPrice}</Label>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell textAlign="left">Shipping</Table.Cell>
              <Table.Cell textAlign="right">
                <Label tag>${this.props.shippingAmount}</Label>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell textAlign="left">Total Price ($USD)</Table.Cell>
              <Table.Cell textAlign="right">
                <Label tag  style={{ backgroundColor: config.colors.primary, color: 'white' }}>
                  $
                  {(
                    Number(this.props.orderPrice) +
                    Number(this.props.shippingAmount)
                  ).toFixed(2)}
                </Label>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <p style={{ textAlign: 'justify' }}>
          <Icon name="lock" style={{ color: config.colors.secondary}} />
          All of your credit card details are processed by Stripe and kept
          secure at all times. We do not keep a copy of your card information.
        </p>
        <Segment style={{ padding: '8px', textAlign: 'right' }}>
          <Form onSubmit={this.handleSubmit}>
            <CardSection />

            <div style={{ margin: '4vw 0', textAlign: 'right' }}>
              {this.shippingMessage()}
              <Button
                style={{ margin: '0', backgroundColor: config.colors.primary, color: 'white' }}
                loading={this.state.confirmOrderButtonLoader}
                disabled={this.state.confirmOrderButtonDisabled}
              >
                Confirm $
                {(
                  Number(this.props.orderPrice) +
                  Number(this.props.shippingAmount)
                ).toFixed(2)}
              </Button>
            </div>
          </Form>
        </Segment>

        <Modal centered={true} open={this.state.showModal}>
          <Modal.Header>
            <Icon circular size="large" color="red" name="cancel" />
            Payment Failure
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Header>We had a problem with your card</Header>
              <p>{this.state.response}</p>
              <p>
                Please double check your information or try again with a
                different card.
              </p>
            </Modal.Description>

            <Button onClick={() => this.setState({ showModal: false })}>
              Close
            </Button>
          </Modal.Content>
        </Modal>
      </Segment>
    )
  }
}

const mapStateToProps = state => {
  return {
    orderPrice: state.orderPrice,
    shippingName: state.shippingName,
    clientEmail: state.clientEmail,
    shippingAddress1: state.shippingAddress1,
    shippingAddress2: state.shippingAddress2,
    shippingCity: state.shippingCity,
    shippingState: state.shippingState,
    shippingZip: state.shippingZip,
    shippingCountry: state.shippingCountry,
    shippingRates: state.shippingRates,
    shippingOption: state.shippingOption,
    shippingID: state.shippingID,
    shippingAmount: state.shippingAmount,
    stripeToken: state.stripeToken,
    billingName: state.billingName,
    billingAddress1: state.billingAddress1,
    billingAddress2: state.billingAddress2,
    billingCity: state.billingCity,
    billingState: state.billingState,
    billingZip: state.billingZip,
    billingCountry: state.billingCountry,
    showBilling: state.showBilling,
    cart: state.cart,
    paymentModal: state.paymentModal,
  }
}

export default connect(mapStateToProps)(injectStripe(CheckoutForm))

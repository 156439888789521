import React from 'react'
import { CardElement } from 'react-stripe-elements'
import { Image, Grid, Segment } from 'semantic-ui-react'

import visa from '../../../images/cards/visa.png'
import mcard from '../../../images/cards/mcard.png'
import amex from '../../../images/cards/amex.png'
import disc from '../../../images/cards/disc.png'

class CardSection extends React.Component {
  render() {
    return (
      <div>
        <Grid columns={4}>
          <Grid.Row>
            <Image size="tiny" style={{ margin: '0 0 10px 7px' }} src={visa} />
            <Image size="tiny" style={{ margin: '0 0 10px 7px' }} src={mcard} />
            <Image size="tiny" style={{ margin: '0 0 10px 7px' }} src={amex} />
            <Image size="tiny" style={{ margin: '0 0 10px 7px' }} src={disc} />
          </Grid.Row>
        </Grid>
        <Segment>
        <CardElement style={{ base: { fontSize: '18px' } }} />
        </Segment>
      </div>
    )
  }
}

export default CardSection

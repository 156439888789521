import React, { Component } from 'react'
// import dotenv from 'dotenv'

import { connect } from 'react-redux'
import { StripeProvider } from 'react-stripe-elements'
import Layout from '../layouts/product'

import CartPage from '../components/cart/cartPage'
import StepContainer from '../components/cart/stepContainer'
import Shipping from '../components/cart/shipping'
import Payment from '../components/cart/payment'
import Confirm from '../components/cart/confirm'

class Cart extends Component {
  constructor() {
    super();
    this.state = {stripe: null};
  }
  // process.env.STRIPE_PUBLISHABLE_KEY
  componentDidMount() {
    if (window.Stripe) {
      this.setState({stripe: window.Stripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)});
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({stripe: window.Stripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)});
      });
    }
  }

  render() {
    if (this.props.cart.length > 0) {
      switch (this.props.stepper) {
        case 'cart':
          return (
            <Layout>
              <StepContainer />
              <CartPage />
            </Layout>
          )
        case 'shipping':
          return (
            <Layout>
              <StepContainer />
              <Shipping />
            </Layout>
          )
        case 'payment':
          return (
            <Layout>
              <StepContainer />
              <StripeProvider stripe={this.state.stripe}>
                <Payment />
              </StripeProvider>
            </Layout>
          )
        case 'confirm':
          return (
            <Layout>
              <StepContainer />
              <Confirm />
            </Layout>
          )
        default:
          return (
            <Layout>
              <p>Quit playing with my state and buy a Hat already</p>
            </Layout>
          )
      }
    } else {
      return (
        <Layout>
          <StepContainer />
          <CartPage />
        </Layout>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    stepper: state.stepper,
    cart: state.cart,
  }
}

export default connect(mapStateToProps)(Cart)

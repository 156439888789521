import React, { Component } from 'react'
import {
  Container,
  Header,
  Form,
  Segment,
  Button,
  Checkbox,
} from 'semantic-ui-react'

class Confirm extends Component {
  constructor() {
    super()
    this.state = {
      addressToggle: true,
    }
  }

  handleChange = e => {}

  billingAddress = () => {
    return (
      <Segment>
        <Segment>
          <Container fluid>
            <Header as="h1">Payment Information</Header>
            <Header as="h3">
              Use shipping address for billing<Checkbox
                defaultChecked
                toggle
                onChange={e => {
                  if (this.state.addressToggle === true) {
                    this.setState({ addressToggle: false })
                    
                  } else {
                    this.setState({ addressToggle: true })
                  }
                }}
              />
            </Header>
            <Form>
              <Form.Group widths="equal">
                <Form.Input fluid label="Name" placeholder="Full Name" />
                <Form.Input
                  fluid
                  name="email"
                  label="Email Address"
                  placeholder="Email Address"
                  // value={this.props.email}
                />
                <Form.Input
                  fluid
                  label="Confirm Email"
                  placeholder="Confirm Email"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  label="Street Address"
                  placeholder="Street Address"
                />
                <Form.Input
                  fluid
                  label="Suite or Appartment #"
                  placeholder="Suite or Appartment #"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input fluid label="City" placeholder="City" />
                <Form.Input fluid label="State" placeholder="State" />
                <Form.Input
                  fluid
                  label="ZIP or Postal Code"
                  placeholder="ZIP or Postal Code"
                />
                <Form.Input fluid label="Country" placeholder="Country" />
              </Form.Group>
              <Form.Button color="pink">Submit</Form.Button>
            </Form>
          </Container>
          <Container fluid textAlign="right">
            <Button color="pink">Payment...</Button>
          </Container>
        </Segment>

        <Segment>
          <Form>
            <Form.Group widths="equal">
              <Form.Input fluid label="Name" placeholder="Full Name" />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="Street Address"
                placeholder="Street Address"
              />
              <Form.Input
                fluid
                label="Suite or Appartment #"
                placeholder="Suite or Appartment #"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input fluid label="City" placeholder="City" />
              <Form.Input fluid label="State" placeholder="State" />
              <Form.Input
                fluid
                label="ZIP or Postal Code"
                placeholder="ZIP or Postal Code"
              />
              <Form.Input fluid label="Country" placeholder="Country" />
            </Form.Group>
            <Form.Button color="pink">Submit</Form.Button>
          </Form>
        </Segment>
      </Segment>
    )
  }

  paymentInfo = () => {
    return (
      <Segment>
        <Segment>
          <Container fluid>
            <Header as="h1">Payment Information</Header>
            <Header as="h3">
              Use shipping address for billing<Checkbox
                defaultChecked
                toggle
                onChange={e => {
                  if (this.state.addressToggle === true) {
                    this.setState({ addressToggle: false })
                    console.log(this.state.addressToggle)
                  } else {
                    this.setState({ addressToggle: true })
                    console.log(this.state.addressToggle)
                  }
                }}
              />
            </Header>
            <Form>
              <Form.Group widths="equal">
                <Form.Input fluid label="Name" placeholder="Full Name" />
                <Form.Input
                  fluid
                  name="email"
                  label="Email Address"
                  placeholder="Email Address"
                  // value={this.props.email}
                />
                <Form.Input
                  fluid
                  label="Confirm Email"
                  placeholder="Confirm Email"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  label="Street Address"
                  placeholder="Street Address"
                />
                <Form.Input
                  fluid
                  label="Suite or Appartment #"
                  placeholder="Suite or Appartment #"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input fluid label="City" placeholder="City" />
                <Form.Input fluid label="State" placeholder="State" />
                <Form.Input
                  fluid
                  label="ZIP or Postal Code"
                  placeholder="ZIP or Postal Code"
                />
                <Form.Input fluid label="Country" placeholder="Country" />
              </Form.Group>
              <Form.Button color="pink">Submit</Form.Button>
            </Form>
          </Container>
          <Container fluid textAlign="right">
            <Button color="pink">Payment...</Button>
          </Container>
        </Segment>
      </Segment>
    )
  }

  render() {
    if (this.state.addressToggle === false) {
      return this.billingAddress()
    } else {
      return this.paymentInfo()
    }
  }
}

export default Confirm

import React, { Component } from 'react'
import {connect} from 'react-redux'
import config from '../../config.jsx'


import { Container, Icon, Step, Responsive } from 'semantic-ui-react'

class StepContainer extends Component {

  updateStepper = (stage) => {
    this.props.dispatch({type: 'STEPPER_STATE', stepper: stage})
  }

  render = () => {
    return (
      <Container style={{marginTop: '120px'}} fluid>
      <Step.Group unstackable attached="top" widths={3} >

        <Step link active={this.props.stepper === 'cart'} onClick={() => {
          this.updateStepper('cart')
          }}>
          <Icon style={{color: config.colors.secondary}} name="shopping cart" />
          <Responsive minWidth={767}>
          <Step.Content>
          
            <Step.Title style={{color: config.colors.primary}}>Review Order</Step.Title>
            
            <Step.Description>
              Verify your items
            </Step.Description>
            
          </Step.Content>
          </Responsive>
        </Step>

        <Step link active={this.props.stepper === 'shipping'} onClick={() => {
          this.updateStepper('shipping')
          }}>
          <Icon style={{color: config.colors.secondary}} name="truck" />
          <Responsive minWidth={767}>
          <Step.Content>
          
            <Step.Title style={{color: config.colors.primary}}>Shipping</Step.Title>
            
            <Step.Description>Enter shipping details and select option</Step.Description>
            
          </Step.Content>
          </Responsive>
        </Step>

        <Step link active={this.props.stepper === 'payment'} onClick={() => {
          this.updateStepper('payment')
        }}>
          <Icon style={{color: config.colors.secondary}} name="payment" />
          <Responsive minWidth={767}>
          <Step.Content>
          
            <Step.Title style={{color: config.colors.primary}}>Confirm Order</Step.Title>
            
            <Step.Description>
              Enter payment information and confirm
            </Step.Description>
            
          </Step.Content>
          </Responsive>
        </Step>
      </Step.Group>
    </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    stepper: state.stepper,
  }
}

export default connect(mapStateToProps)(StepContainer)
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import MiniPic from '../product/miniPic'
import PicData from '../product/PicData'

const ImageDataCart = ({ data, name, productColor }) => {
  
  return (
    <PicData data={data} name={name} color={productColor}>
      <MiniPic />
    </PicData>
  )
}
export default props => (
  <StaticQuery
    query={graphql`
      query imageDataCart {
        allImageSharp {
          edges {
            node {
              fluid(traceSVG: { color: "#BB133E" }) {
                aspectRatio
                tracedSVG
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
        allSitePage(filter: { context: { title: { ne: null } } }) {
          edges {
            node {
              context {
                id
                title
                description
                quote1
              }
            }
          }
        }
      }
    `}
    render={data => <ImageDataCart data={data} {...props} />}
  />
)

//Cartpage shows all of the products from the redux store in a table. If there are no products then it shows empty cart. Remove option deletes line item from Redux Store.

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import ImageDataCart from '../product/imageDataCart'
import config from '../../config'
// import Img from 'gatsby-image'

import {
  Header,
  Table,
  Label,
  Button,
  Segment,
  Select,
  Input,
  Responsive,
  Grid,
} from 'semantic-ui-react'

class CartPage extends Component {
  deleteItem(index) {
    this.props.dispatch({ type: 'REMOVE_FROM_CART', key: index })
    this.props.dispatch({ type: 'UPDATE_TOTALS' })
  }

  updateStepper = stage => {
    this.props.dispatch({ type: 'STEPPER_STATE', stepper: stage })
  }

  updateValue(key, cartItemToUpdate, valueToUpdate) {
    this.props.dispatch({
      type: 'UPDATE_CART_ITEM',
      key: key,
      item: cartItemToUpdate,
      value: valueToUpdate,
    })
    this.props.dispatch({ type: 'UPDATE_TOTALS' })
  }

  tableHeader = () => {
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Design</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Color</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Qty</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Unit Price</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Price</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    )
  }

  fullCart = () => {
    return (
      <Segment style={{ marginTop: '1rem' }}>
        <Header as="h1">Your Cart</Header>
        <Responsive minWidth={768}>
          <Table padded>
            {this.tableHeader()}
            <Table.Body>
              {this.props.cart.map((product, key) => {
               
                return (
                  <Table.Row key={key}>
                    <Table.Cell>
                      <Button
                        style={{ backgroundColor: config.colors.secondary }}
                        negative
                        icon="delete"
                        size="mini"
                        onClick={() => {
                          this.deleteItem(key)
                        }}
                      />
                    </Table.Cell>

                    <Table.Cell style={{ maxWidth: '80px' }}>
                      <ImageDataCart
                        name={product.name}
                        productColor={product.color}
                      />
                    </Table.Cell>

                    <Table.Cell>
                      <span className="redunderline">{product.name}</span>
                    </Table.Cell>

                    <Table.Cell style={{ maxWidth: '120px' }} textAlign="right">
                      <Select
                        fluid
                        name="color"
                        options={config.hatColors}
                        value={this.props.cart[key].color}
                        onChange={(e, data) =>
                          this.updateValue(key, 'color', data.value)
                        }
                      />
                    </Table.Cell>

                    <Table.Cell style={{ minWidth: '100px' }} textAlign="right">
                      <Input
                        fluid
                        min={1}
                        type="number"
                        name="qty"
                        //pattern="/[0-9]/"
                        value={this.props.cart[key].qty}
                        onChange={(e, data) =>
                          this.updateValue(key, 'qty', data.value)
                        }
                      />
                    </Table.Cell>

                    <Table.Cell textAlign="right">
                      ${product.price / 100}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Label tag>
                        ${((product.price * product.qty) / 100).toFixed(2)}
                      </Label>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
              <Table.Row>
                <Table.Cell colSpan="6" textAlign="right">
                  Shipping
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Label tag>T.B.D</Label>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell colSpan="6" textAlign="right">
                  Total Price ($USD)
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Label tag style={{ color: config.colors.primary }}>
                    ${this.props.orderPrice}
                  </Label>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Responsive>

        <Responsive maxWidth={767}>
          {this.props.cart.map((product, key) => {
            return (
              <Segment key={key}>
                <Grid>
                  <Grid.Column width={2}>
                    <Button
                      style={{ backgroundColor: config.colors.secondary }}
                      negative
                      icon="delete"
                      size="mini"
                      onClick={() => {
                        this.deleteItem(key)
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column
                    width={14}
                    textAlign="center"
                    verticalAlign="middle"
                  >
                    <Header as="h3">
                      <span className="redunderline">{product.name}</span>
                    </Header>
                  </Grid.Column>
                </Grid>

                <Grid>
                  <Grid.Column width={8}>
                    <ImageDataCart
                      name={product.name}
                      productColor={product.color}
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    Color:
                    <Select
                      fluid
                      name="color"
                      options={config.hatColors}
                      value={this.props.cart[key].color}
                      onChange={(e, data) =>
                        this.updateValue(key, 'color', data.value)
                      }
                    />
                    Quantity:
                    <Input
                      fluid
                      min={1}
                      type="number"
                      name="qty"
                      //pattern="/[0-9]/"
                      value={this.props.cart[key].qty}
                      onChange={(e, data) =>
                        this.updateValue(key, 'qty', data.value)
                      }
                    />
                  </Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column textAlign="right" width={16}>
                    <Grid.Column width={4}>
                      <Header as="h3">
                        <Label tag>${product.price / 100} each.</Label>
                      </Header>
                    </Grid.Column>
                  </Grid.Column>
                </Grid>
              </Segment>
            )
          })}
          <Table>
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign="right">Shipping</Table.Cell>
                <Table.Cell textAlign="right">
                  <Label tag>T.B.D</Label>
                </Table.Cell>
              </Table.Row>

              {/* <Table.Row>
                <Table.Cell colSpan="6" textAlign="right">
                  Tax
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Label tag>
                    ${((this.props.cart[0].price / 100) * 0.15).toFixed(2)}
                  </Label>
                </Table.Cell>
              </Table.Row> */}
              <Table.Row>
                <Table.Cell textAlign="right">Total Price ($USD)</Table.Cell>
                <Table.Cell textAlign="right">
                  <Label horizontal size="huge" tag>
                    ${this.props.orderPrice}
                  </Label>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Responsive>
        {/* <Container style={{marginTop: '10px', marginRight: '0px'}} textAlign="right"> */}
        <div
          style={{ marginTop: '10px', marginRight: '0px', textAlign: 'right' }}
        >
          <Button
            style={{
              marginRight: '0px',
              backgroundColor: config.colors.primary,
              color: 'white',
            }}
            onClick={() => this.updateStepper('shipping')}
          >
            Calculate Shipping...
          </Button>
        </div>
        {/* </Container> */}
      </Segment>
    )
  }

  emptyCart = () => {
    return (
      <Segment>
        <Header as="h1">Your Cart</Header>
        <Responsive minWidth={768}>
          <Table>
            {this.tableHeader()}
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan="8" textAlign="center">
                  <Header as="h2">
                    You have no items in your cart.{' '}
                    <Link to="/#hatstore">
                      <Button
                        style={{
                          backgroundColor: config.colors.primary,
                          color: 'white',
                        }}
                      >
                        Go to store
                      </Button>{' '}
                    </Link>
                  </Header>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Responsive>
        <Responsive maxWidth={767}>
          <Table>
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan="8" textAlign="center">
                  <Header as="h2">
                    You have no items in your cart. <br />
                    <Link to="/#hatstore">
                      <Button
                        style={{
                          backgroundColor: config.colors.primary,
                          color: 'white',
                        }}
                      >
                        Go to store
                      </Button>{' '}
                    </Link>
                  </Header>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Responsive>
      </Segment>
    )
  }

  render() {
    if (this.props.cart.length > 0) {
      return <div>{this.fullCart()}</div>
    } else {
      return this.emptyCart()
    }
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart,
    orderPrice: state.orderPrice,
    stepper: state.stepper,
  }
}

export default connect(mapStateToProps)(CartPage)
